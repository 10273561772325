import { Badge, Dropdown, Space, SpaceCompact, Table, Tag, Tooltip, Typography } from '@ui';
import { tableListToOptions } from '@shared/utils/Table';
import { libraryModelActions } from '@modules/library/model/duck/libraryModelSlice';
import { ELibraryModelModalsType } from '@modules/library/model/modals';
import { dateToString } from '@shared/utils/Date';
import { useColumnSearch } from '@components/ui/table/tableHooks';
import { modelActions } from '@modules/model/duck/modelSlice';
import { ModelModalsType } from '@modules/model/modals';
import { StoreListResponse } from '@modules/stores/duck/storeApi';
import { viewerActions } from '@modules/viewer/duck/viewerSlice';
import { getStudyPathName } from '@routes/utils';
import routes from '@routes';
import { ActorAndInfo, cssInfo } from '@components';
import { useFeatures, useStudyPermissions } from '@modules/user/duck/userHooks';
import { AntdIconBox } from '@components/icons';
import { getRunModelStatusByName } from '@modules/model/duck/modelUtils';
import { ModelActionButtons } from '@modules/model/components/ModelActionButtons';
import { TableColumnsType, TableProps } from 'antd';
import React, { ReactNode, useMemo } from 'react';
import { TFunction } from 'i18next';
import { CSSObject, Theme } from '@emotion/react';
import { DownOutlined, ExportOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ColumnType } from 'antd/lib/table';
import { Model, ModelListResponse, RunModelStatus } from '../ModelTypes';

const dashSymbol = <>&mdash;</>;

export const ModelList = ({
  data,
  storeList,
  loading,
  studyId,
  pagination,
  onChange,
  isCurrentModelEnv,
  modelEnv,
  modelEnvLabel,
  t,
  statuses,
  statusesLoading,
}: ModelListProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    userPermissions: { canDataModelInsert, canDataModelRun },
  } = useStudyPermissions();
  const { hasGPDIP, hasGL } = useFeatures();
  const { getColumnSearchProps, locale } = useColumnSearch<ModelListResponse['items'][0]>();
  const storeListOption = tableListToOptions(storeList);

  const actionMenuItems = [
    ...(isCurrentModelEnv && canDataModelRun ? [{ key: 'viewLog', label: t('rootTable.actionMenu.viewLog') }] : []),
    { key: 'viewData', label: t('rootTable.actionMenu.viewData') },
    ...(canDataModelInsert && hasGL ? [{ key: 'exportToGL', label: t('rootTable.actionMenu.exportToGL') }] : []),
  ].filter((item) => typeof item === 'object') as Array<{ key: string; label: string }>;

  const openModelModal = (data: ModelListResponse['items'][0]) => {
    dispatch(modelActions.pushModal({ type: ModelModalsType.saveModel, data }));
  };

  const onShowModelLog = (id: number, name: string) => {
    dispatch(
      modelActions.pushModal({
        type: ModelModalsType.openLogs,
        data: { modelId: id, tableName: name, tableId: name },
      }),
    );
  };

  const columns: TableColumnsType<ModelListResponse['items'][0]> = useMemo(
    () =>
      [
        {
          title: t('rootTable.name'),
          dataIndex: 'name',
          // TODO add tag here after adding logic for intermediate
          ...getColumnSearchProps('name'),
          onFilter: undefined,
          render: (name: ReactNode, record: Model) => (
            <Space>
              <Typography.Link children={name} onClick={() => openModelModal(record)} />
              {!record.active && <Tag color="cyan" children={t('rootTable.noActive')} />}
            </Space>
          ),
        },
        {
          title: t('rootTable.dataStore'),
          dataIndex: 'data_store_id',
          filters: storeListOption,
          onFilter: undefined,
          render: (_: any, record: Model) => record.data_store.name,
        },
        {
          title: t('rootTable.updated'),
          dataIndex: 'updated_at',
          sorter: () => 0,
          sortDirections: ['ascend'],
          render: (updatedAt: number, record: Model) => (
            <ActorAndInfo info={dateToString(updatedAt)} actor={record.updated_by} />
          ),
        },
        {
          title: t('rootTable.finishedAt'),
          dataIndex: 'finished_at',
          key: 'finished_at',
          render: (finished_at: number, record: Model) => {
            const finishedTime = (
              <Typography.Text
                css={cssInfo('xs')}
                type="secondary"
                children={finished_at ? dateToString(finished_at) : dashSymbol}
              />
            );
            const success = <Badge css={cssBadge} status="success" text={t('rootTable.status.finished')} />;
            const error = <Badge css={cssBadge} status="default" text={t('rootTable.status.unknown')} />;
            const unknownStatus = <Badge css={cssBadge} status="error" text={t('rootTable.status.failed')} />;

            if (record.error) {
              return (
                <SpaceCompact direction="vertical">
                  <Space>
                    {record.failed_logs ? error : unknownStatus}
                    <AntdIconBox
                      icon={QuestionCircleOutlined}
                      tip={t('rootTable.tooltipError')}
                      color="danger"
                      onClick={() => onShowModelLog(record.id, record.name)}
                    />
                  </Space>
                  {finishedTime}
                </SpaceCompact>
              );
            }

            return (
              <SpaceCompact direction="vertical">
                {record.finished_at && success}
                {finishedTime}
              </SpaceCompact>
            );
          },
        },
        {
          width: 90,
          title: t('rootTable.build'),
          dataIndex: 'buildStates',
          render: (_: any, record: Model) => {
            if (!record.active) {
              return t('rootTable.schedulerStates.disabled');
            }
            if (record.job.automatic) {
              return t('rootTable.schedulerStates.auto');
            }
            if (record.job.run_once) {
              return t('rootTable.schedulerStates.once');
            }
            return t('rootTable.schedulerStates.period');
          },
        },
        hasGPDIP && {
          width: 90,
          title: t('rootTable.sync'),
          dataIndex: 'syncStates',
          render: (_: any, record: Model) => {
            if (!record.active) {
              return t('rootTable.syncStates.disabled');
            }
            if (record.export.automatic) {
              const getExportInfo = record.export ? (
                <Tooltip title={t('rootTable.syncExport')}>
                  <ExportOutlined />
                </Tooltip>
              ) : null;

              return (
                <Space>
                  {t('rootTable.syncStates.auto')}
                  {getExportInfo}
                </Space>
              );
            }
            if (!record.export.automatic && !record.export.crontab) {
              return t('rootTable.syncStates.manual');
            }

            return t('rootTable.syncStates.period');
          },
        },
        {
          width: 200,
          title: t('rootTable.actions'),
          dataIndex: 'actionMenu',
          render: (_: any, record: Model) => {
            const status = getRunModelStatusByName(statuses, record.name);
            return (
              <Space>
                <ModelActionButtons
                  record={record}
                  status={status}
                  loading={statusesLoading}
                  isCurrentModelEnv={isCurrentModelEnv}
                  studyId={studyId}
                  modelEnvLabel={modelEnvLabel}
                  t={t}
                />
                <Dropdown
                  menu={{
                    items: actionMenuItems,
                    onClick: async ({ key }) => {
                      switch (key) {
                        case 'viewLog':
                          onShowModelLog(record.id, record.name);
                          break;
                        case 'viewData':
                          dispatch(viewerActions.setLeftSideData({}));
                          navigate(
                            routes[getStudyPathName(studyId)].dataViewer.resolver({
                              studyId,
                              tableId: record.name,
                            }),
                            { state: { external: true } },
                          );
                          break;
                        case 'exportToGL':
                          dispatch(
                            libraryModelActions.pushModal({
                              type: ELibraryModelModalsType.copyModel,
                              data: { model: { ...record, env: modelEnv } },
                            }),
                          );
                          break;
                        default:
                          break;
                      }
                    },
                  }}
                  trigger={['click']}
                  placement="bottomRight"
                  autoAdjustOverflow={false}
                >
                  <button onClick={(e) => e.preventDefault()} css={cssActionBtn}>
                    {t('rootTable.actionMenu.label')} <DownOutlined />
                  </button>
                </Dropdown>
              </Space>
            );
          },
        },
      ].filter((item) => typeof item !== 'boolean') as ColumnType<Model>[],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [storeListOption, modelEnv, statuses, statusesLoading, isCurrentModelEnv, modelEnvLabel, studyId, actionMenuItems],
  );

  return (
    <Table
      css={cssTable}
      bordered
      locale={locale}
      columns={columns}
      dataSource={data}
      loading={loading}
      rowKey={(item) => item.id}
      onChange={onChange}
      tableLayout="fixed"
      scroll={{ x: 900 }}
      pagination={pagination}
    />
  );
};

export interface ModelListProps {
  studyId: number;
  data?: ModelListResponse['items'];
  storeList?: StoreListResponse['items'];
  pagination?: TableProps<ModelListResponse['items'][0]>['pagination'];
  onChange?: TableProps<ModelListResponse['items'][0]>['onChange'];
  loading?: boolean;
  pageSize?: number;
  disableKindSort?: boolean;
  isCurrentModelEnv?: boolean;
  modelEnv?: string;
  modelEnvLabel?: string;
  t: TFunction;
  statuses: RunModelStatus[];
  statusesLoading: boolean;
}

const cssTable = (): CSSObject => ({
  '&&&& td.ant-table-cell': {
    paddingTop: '9.5px',
    paddingBottom: '9.5px',
  },
});

const cssActionBtn = (theme: Theme): CSSObject => ({
  border: 'none',
  background: 'none',
  color: theme.colorLink,
  cursor: 'pointer',
});

const cssBadge = (): CSSObject => ({
  '&& .ant-badge-status-text': {
    fontSize: '12px',
  },
});
