import { selectStudyUserPermissions, selectStudyUserPermissionsReady } from '@modules/study/duck/studySelectors';
import { DEPLOYMENT_FEATURE_NAME, GL_FEATURE_NAME, GPDIP_FEATURE_NAME } from '@config/constants';
import { useSelector } from 'react-redux';

export const useStudyPermissions = () => {
  const userPermissions = useSelector(selectStudyUserPermissions);
  const userPermissionsReady = useSelector(selectStudyUserPermissionsReady);

  return {
    userPermissions,
    userPermissionsReady,
  };
};

export const useFeatures = () => {
  const hasFeature = (featureName: string) => !!globalThis.FEATURE_FLAG?.includes(featureName);

  return {
    hasGPDIP: hasFeature(GPDIP_FEATURE_NAME),
    hasGL: hasFeature(GL_FEATURE_NAME),
    hasDeployment: hasFeature(DEPLOYMENT_FEATURE_NAME),
  };
};
