import { Form, FormItem, FormLayout, Select, Radio, Table, notification } from '@ui';
import {
  ELibraryEntityNames,
  ELibrarySourceType,
  ESelectedActions,
  IBaseLibraryEntitySystemInfo,
} from '@modules/library/root/LibraryTypes';
import { useTablePaginationState } from '@components/ui/table/tableHooks';
import {
  useLazyEnvCDRListQuery,
  useLazyEnvStudyListQuery,
  useLazyLibraryListQuery,
} from '@modules/library/root/duck/libraryApi';
import { useFeatures } from '@modules/user/duck/userHooks';
import { LibraryStatus } from '@modules/library/root/duck/libraryConstants';
import { itemsToOptions } from '@shared/utils/Form';
import { selectAuthUser } from '@modules/auth/duck/AuthSelector';
import { SupportedEnvs } from '@app/AppTypes';
import { IBaseColumnProps } from '@shared/components/ObjectTable';
import { selectGlobalLibrary } from '@app/duck/appSelectors';
import { QueryErrorType } from '@shared/utils/Error';
import { getReportVersionLabel } from '@shared/utils/common';
import { IAnalysisObjectCDRListItem } from '@modules/library/analysisObjects/cdr/AnalysisObjectCDRTypes';
import { Col, Row, TableColumnsType, TablePaginationConfig } from 'antd';
import { CSSObject } from '@emotion/react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ECdrType, ICDRReport, ILibraryCDR } from '../LibraryCdrTypes';
import { useLazyLibraryCdrListQuery } from '../duck/libraryCdrApi';

const initialPage = {
  current: 1,
  pageSize: 10,
  pageSizeOptions: [],
};

export interface GetLibraryImportCDRFromLibrarySource extends IBaseColumnProps {
  type: ECdrType;
  sourceVersion?: number | string | null;
  libraryVersion?: number | string;
  source?: string;
  rawData: ILibraryCDR | IAnalysisObjectCDRListItem | ICDRReport;
}

export const getLibraryImportCDRFromLibrarySource = (
  data: ILibraryCDR[] | ICDRReport[],
  kind: ELibrarySourceType,
): Array<GetLibraryImportCDRFromLibrarySource> => {
  if (!Array.isArray(data)) return [];

  if (kind === ELibrarySourceType.Library) {
    return (data as ILibraryCDR[]).map((item) => ({
      id: item.id,
      name: item.name,
      type: item.object_type,
      sourceVersion: getReportVersionLabel(item.configuration?.version_label),
      rawData: item,
      version_id: item.version_id,
      libraryVersion: item.version,
      source: item.source,
    }));
  }

  return (data as ICDRReport[]).map((item) => ({
    id: item.id,
    name: item.name,
    type: item.type || ECdrType.Report,
    sourceVersion: getReportVersionLabel(item?.version_label),
    rawData: item,
  }));
};

export const LibraryImportCDR = ({
  onClose,
  columns,
  sourceOnlyLibrary,
  locale,
  kind,
  libraryStatuses,
  hideOverwriteHandles,
  onImport,
  onlyCurrentEnv,
}: ILibraryImportCDRProps) => {
  const globalLibrary = useSelector(selectGlobalLibrary);
  const user = useSelector(selectAuthUser);
  const [form] = Form.useForm();
  const { t } = useTranslation(['libraryRoot']);
  const { setPagination, getPagination } = useTablePaginationState(initialPage);
  const { hasGL } = useFeatures();

  const [columnsFiltered, setColumnsFiltered] = useState(columns);
  const [sourceData, setSourceData] = useState<TSourceDataProps>([]);
  const [selectedFilter, setSelectedFilter] = useState<ESelectedActions>(ESelectedActions.SHOW_ALL);
  const [libraryListQuery, libraryListQueryData] = useLazyLibraryListQuery();
  const [envStudyListQuery, studiesListQueryData] = useLazyEnvStudyListQuery();
  const [libraryCDRListQuery, libraryCDRListQueryData] = useLazyLibraryCdrListQuery();
  const [studyCdrListQuery, studyCdrListQueryData] = useLazyEnvCDRListQuery();

  const [tableData, setTableData] = useState<ILibraryImportCDR[]>([]);
  const [selectedTableItems, setSelectedTableItems] = useState<React.Key[]>([]);

  const tableDataFetching = libraryCDRListQueryData.isFetching || studyCdrListQueryData.isFetching;
  const fetchingNames = libraryListQueryData.isFetching || studiesListQueryData.isFetching;

  const pagination = getPagination(tableData.length);

  const userCurrentEnv = user?.env_name ?? '';
  const allUserEnvs = user?.environments;
  const currentEnvLabel = allUserEnvs && allUserEnvs[userCurrentEnv]?.label;

  const sourceType = useMemo(() => {
    const sourceOptions = [];

    if (hasGL) {
      sourceOptions.push({
        label: ELibrarySourceType.Library,
        value: ELibrarySourceType.Library,
      });
    }

    if (onlyCurrentEnv) {
      sourceOptions.push({ label: `Study (${currentEnvLabel})`, value: userCurrentEnv } as any);
      return sourceOptions;
    }

    if (!sourceOnlyLibrary) {
      sourceOptions.push(
        ...Object.entries(allUserEnvs || ({} as SupportedEnvs)).map(([env, envData]) => {
          return { label: `Study (${envData!.label})`, value: env } as any;
        }),
      );
    }
    return sourceOptions;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allUserEnvs]);

  const initValues = {
    sourceKind: hasGL && ELibrarySourceType.Library,
    overwrite: false,
  };

  const onChangeSource = useCallback(
    async (value: string) => {
      setTableData([]);
      setSelectedTableItems([]);
      setSourceData([]);
      form.setFieldValue('sourceId', '');
      if (value === ELibrarySourceType.Library) {
        setColumnsFiltered(columns);
        const data = await libraryListQuery({
          status: libraryStatuses ? libraryStatuses.join(',') : undefined,
          page_size: 999,
        }).unwrap();
        const libraryListOption = itemsToOptions(data?.items?.filter((item) => item.id !== globalLibrary?.id));
        setSourceData(libraryListOption);
      } else if (value) {
        // TODO: maybe after updating Antd to 5.13 version we can use property 'hidden' directly in the column and remove state variable
        setColumnsFiltered(columns.filter((el) => el.key && !['libraryVersion', 'source'].includes(el.key as string)));
        const data = await envStudyListQuery({ env: value }).unwrap();
        const studiesListOption = itemsToOptions(data);
        setSourceData(studiesListOption);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [libraryListQuery, envStudyListQuery],
  );

  useEffect(() => {
    if (hasGL) {
      onChangeSource(ELibrarySourceType.Library);
    }
  }, [onChangeSource, hasGL]);

  const onChangeSourceId = async (id: number) => {
    try {
      const source = form.getFieldValue('sourceKind');
      if (source === ELibrarySourceType.Library) {
        const data = await libraryCDRListQuery({ library_id: id, detailed: '1' }).unwrap();
        setTableData(getLibraryImportCDRFromLibrarySource(data.items, ELibrarySourceType.Library));
      } else if (source) {
        const data = await studyCdrListQuery({
          study_id: id,
          env: source,
        }).unwrap();
        setTableData(getLibraryImportCDRFromLibrarySource(data, ELibrarySourceType.Study));
      }
    } catch (e) {
      console.error('Error while changing source id', e);
    }
  };

  const onSubmit = async (values: ILibraryCopyFieldFormValues) => {
    try {
      let options: IOnImportOptions = { overwrite: values.overwrite, kind: ELibrarySourceType.Library };

      if (values['sourceKind'] && values['sourceKind'] !== ELibrarySourceType.Library) {
        const studyName = sourceData.find((item) => item.value === values['sourceId'])?.label!;

        options = {
          ...options,
          kind: ELibrarySourceType.Study,
          systemInfo: {
            env: values['sourceKind'],
            tenant_info: user!.tenant_info,
            source: studyName,
          },
        };
      }

      await onImport(
        tableData.filter((item) => selectedTableItems.includes(item.id)),
        options,
      );
      notification.success({ message: t('copyForm.successMessage', { kind }) });
      onClose();
    } catch (e: any) {
      console.error('Error while importing', e);
      if ((e as QueryErrorType)?.data?.userMsg) {
        notification.error({ message: (e as QueryErrorType)?.data?.userMsg });
      }
    }
  };

  const onTableChange = (tablePagination: TablePaginationConfig) => {
    setPagination(tablePagination.current!);
  };

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: ILibraryImportCDR[]) => {
      setSelectedTableItems(selectedRowKeys);
    },
  };

  const filteredModels = useMemo(() => {
    if (selectedFilter === ESelectedActions.HIDE_SELECTED) {
      return tableData?.filter((model) => !selectedTableItems.includes(model.id));
    }
    return tableData;
  }, [selectedFilter, tableData, selectedTableItems]);

  return (
    <FormLayout
      form={form}
      layout="vertical"
      onCancel={onClose}
      onSubmit={onSubmit}
      okText={t('save')}
      initialValues={initValues}
      submitIsDisabled={selectedTableItems.length === 0}
    >
      <Row gutter={24}>
        <Col span={12}>
          <FormItem
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            name="sourceKind"
            label={t('copyForm.sourceKind')}
            rules={[{ required: true }]}
          >
            <Select placeholder={t('copyForm.sourceKindPlaceholder')} options={sourceType} onChange={onChangeSource} />
          </FormItem>
        </Col>
        <Col span={12}>
          <FormItem
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            name="sourceId"
            label={t('copyForm.sourceId')}
            rules={[{ required: true }]}
            dependencies={['source']}
          >
            <Select
              placeholder={t('copyForm.sourceIdPlaceholder')}
              options={sourceData}
              loading={fetchingNames}
              disabled={fetchingNames}
              onChange={onChangeSourceId}
            />
          </FormItem>
        </Col>
      </Row>
      {!hideOverwriteHandles && (
        <Row gutter={24}>
          <Col span={24}>
            <Form.Item
              name="overwrite"
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 16 }}
              label={t('copyForm.duplicateLabel.label')}
              css={cssHorizontalFormItem}
            >
              <Radio.Group>
                <Radio value={false}>{t('copyForm.duplicateLabel.createCopy')}</Radio>
                <Radio value={true}>{t('copyForm.duplicateLabel.useExisting')}</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
      )}
      <Row gutter={24}>
        <Col span={24}>
          <Form.Item name="table" wrapperCol={{ span: 24 }}>
            <Table
              size="small"
              bordered
              columns={columnsFiltered}
              locale={locale}
              loading={tableDataFetching}
              rowKey="id"
              dataSource={filteredModels}
              pagination={pagination}
              onChange={onTableChange}
              tableLayout="auto"
              rowSelection={{
                preserveSelectedRowKeys: true,
                type: 'checkbox',
                ...rowSelection,
                selectedRowKeys: selectedTableItems,
                selections: [
                  Table.SELECTION_INVERT,
                  {
                    key: ESelectedActions.SHOW_ALL,
                    text: 'Show all',
                    onSelect: () => setSelectedFilter(ESelectedActions.SHOW_ALL),
                  },
                  {
                    key: ESelectedActions.HIDE_SELECTED,
                    text: 'Hide selected',
                    onSelect: () => setSelectedFilter(ESelectedActions.HIDE_SELECTED),
                  },
                ],
              }}
            />
          </Form.Item>
        </Col>
      </Row>
    </FormLayout>
  );
};

const cssHorizontalFormItem = (): CSSObject => ({
  '&& .ant-form-item-row': {
    flexDirection: 'row',
    alignItems: 'center',
  },
  '&& .ant-form-item-label': {
    paddingBottom: 0,
  },
});

export interface ILibraryImportCDRProps {
  onClose: () => void;
  columns: TableColumnsType<ILibraryImportCDR>;
  sourceOnlyLibrary?: boolean;
  locale: Record<string, any>;
  kind: ELibraryEntityNames;
  onImport: (data: ILibraryImportCDR[], options: IOnImportOptions) => void;
  libraryStatuses?: LibraryStatus[];
  hideOverwriteHandles?: boolean;
  onlyCurrentEnv?: boolean;
}

type TSourceDataProps = {
  label: string;
  value: number;
}[];

interface IOnImportOptions {
  overwrite: boolean;
  kind: ELibrarySourceType;
  systemInfo?: IBaseLibraryEntitySystemInfo;
}

export type ILibraryImportCDR = GetLibraryImportCDRFromLibrarySource;

export type WrapperFn = (id: number, env?: string) => Promise<ILibraryImportCDR[]>;

interface ILibraryCopyFieldFormValues {
  sourceKind: ELibrarySourceType;
  sourceId: number;
  overwrite: boolean;
}
