import { Avatar, getMenuItem, LayoutSider, Menu } from '@ui';
import { appMenu, MenuType, studyMenu } from '@routes/appMenu';
import routes from '@routes';
import { CustomRouteMatch } from '@routes/RoutesTypes';
import { selectGlobalLibrary, selectGlobalStudy } from '@app/duck/appSelectors';
import { useFeatures, useStudyPermissions } from '@modules/user/duck/userHooks';
import { CSSObject, Theme } from '@emotion/react';
import { Link, useMatches } from 'react-router-dom';
import { useSelector } from 'react-redux';

export const AppSider = () => {
  const globalStudy = useSelector(selectGlobalStudy);
  const globalLibrary = useSelector(selectGlobalLibrary);
  const matches = useMatches();
  const match = matches.at(-1) as CustomRouteMatch;
  let appLink = routes.app.root.resolver;
  const appMenuItems = appMenu();
  const { userPermissions } = useStudyPermissions();
  const features = useFeatures();

  let libraryMenuItems: MenuType | null = null;
  if (features.hasGL) {
    const { appStudyLibraryMenu } = require('@routes/library/libraryMenu');
    appMenuItems.splice(2, 0, ...appStudyLibraryMenu());

    if (globalLibrary) {
      appLink = require('@routes/library/RoutesLibrary').default.root.resolver;
      libraryMenuItems = require('@routes/library/libraryMenu').libraryMenu(globalLibrary?.id);
    }
  }

  let studyMenuItems: MenuType | null = null;
  if (globalStudy) {
    studyMenuItems = studyMenu(globalStudy?.id, userPermissions, features);
    if (features.hasDeployment) {
      const { appStudyAnalysisObjectsMenu } = require('@routes/library/libraryMenu');
      studyMenuItems.splice(1, 0, ...appStudyAnalysisObjectsMenu(globalStudy?.id, userPermissions));
    }
  }

  const currentMenu =
    appMenuItems.findIndex((item) => item.key === match?.id || item.key === match.handle?.parentId) !== -1
      ? appMenuItems
      : studyMenuItems || libraryMenuItems || appMenuItems;

  const selectedKeys = [match.id];

  if (match.handle?.parentId) {
    selectedKeys.push(match.handle?.parentId);
  }

  return (
    <LayoutSider css={cssSider} width={70}>
      <Link to={appLink()}>
        <Avatar size="large" shape="square" icon={<img src="/static/img/redcapcloud.jpg" alt="Logo" />} />
      </Link>
      <Menu
        css={cssMenu}
        theme="dark"
        selectedKeys={selectedKeys}
        items={currentMenu.map((menu) =>
          getMenuItem({
            label: <Link to={menu.path} children={menu.title} target={menu.path.startsWith('http') ? '_blank' : ''} />,
            key: menu.key,
            icon: menu.icon,
            style: menu.style,
          }),
        )}
      />
      <div css={cssVersion}>{`v${process.env.VERSION}`}</div>
    </LayoutSider>
  );
};

const cssSider = (theme: Theme) => ({
  '& .ant-layout-sider-children': {
    paddingTop: theme.paddingSM,
    textAlign: 'center' as 'center',
  },
  '&&': {
    backgroundColor: theme.colorBgLayoutSecondary,
    color: theme.colorTextBase,
  },
});

const cssMenu = (theme: Theme): CSSObject => ({
  '&& .ant-menu-item-selected': {
    backgroundColor: theme['primary-7'],
    borderRadius: 0,
  },
  '& .ant-menu-item': {
    minHeight: '80px',
    padding: 0,
    margin: 0,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  '&& .ant-menu-item-icon': {
    fontSize: '1.5rem',
    marginBottom: '0.25rem',
  },
  '&&& .ant-menu-title-content': {
    marginInlineStart: 0,
    display: 'inline-block',
    width: '100%',
    whiteSpace: 'break-spaces',
    lineHeight: '1rem',
    fontSize: '0.7rem',
    fontWeight: '500',
    textTransform: 'uppercase',
  },
  '&&': {
    marginTop: theme.paddingSM,
    backgroundColor: theme.colorBgLayoutSecondary,
  },
});

const cssVersion = (theme: Theme): CSSObject => ({
  color: theme.colorTextInverse,
  position: 'absolute',
  bottom: '0',
  left: '50%',
  transform: 'translate(-50%, -50%)',
});
