import { IAnalysisPackage } from '@modules/library/analysisPackage/AnalysisPackageTypes';
import { FormLayout, DraggableModal, Form, FormItem, Input, Typography, notification } from '@ui';
import {
  useAnalysisPackageByIdQuery,
  useSaveAnalysisPackageMutation,
} from '@modules/library/analysisPackage/duck/analysisPackageApi';
import { ILibraryModel } from '@modules/library/model/LibraryModelTypes';
import { ILibraryCDR } from '@modules/library/cdr/LibraryCdrTypes';
import { useAnalysisObjectModelListQuery } from '@modules/library/analysisObjects/model/duck/analysisObjectModelApi';
import { Tabs } from '@components/ui/tabs';
import { useModelColumns } from '@modules/library/model/duck/libraryModelHooks';
import { useCdrColumns } from '@modules/library/cdr/duck/libraryCdrHooks';
import { getLibraryImportCDRFromLibrarySource } from '@modules/library/cdr/components/LibraryImportCDR';
import { getLibraryImportModelFromLibrarySource } from '@modules/library/model/components/LibraryImportModel';
import { IBaseColumnProps, ObjectTable } from '@shared/components/ObjectTable';
import { ELibraryEntityKind, ELibrarySourceType } from '@modules/library/root/LibraryTypes';
import { useAnalysisObjectCDRListQuery } from '@modules/library/analysisObjects/cdr/duck/analysisObjectCDRApi';
import { useSQLDatasetColumns } from '@modules/library/analysisObjects/sqlDataset/duck/analysisObjectSQLDatasetHooks';
import { useAnalysisObjectSQLDatasetListQuery } from '@modules/library/analysisObjects/sqlDataset/duck/analysisObjectSQLDatasetApi';
import { InformationModal } from '@components';
import { renderInfoModalContent } from '@modules/dataset/components';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { useEffect, useState } from 'react';
import { TableColumnsType } from 'antd';

const AnalysisPackageModalsSaveAnalysisPackageContent = ({
  data,
  onClose,
  t,
  isView,
}: AnalysisPackageModalsSaveAnalysisPackageContentProps) => {
  const [form] = Form.useForm();
  const [saveAnalysisPackage, saveAnalysisPackageQuery] = useSaveAnalysisPackageMutation();
  const dataModelAnalysisObjects = useAnalysisObjectModelListQuery();
  const cdrAnalysisObjects = useAnalysisObjectCDRListQuery({ detailed: '1' });
  const sqlDatasetsObjects = useAnalysisObjectSQLDatasetListQuery({ detailed: '1' });
  const analysisPackageById = useAnalysisPackageByIdQuery(data?.id!, { skip: !data?.id });
  const { modelColumns, locale: modelLocale } = useModelColumns();
  const { cdrColumns, locale: cdrLocale } = useCdrColumns();
  const {
    sqlDatasetsColumns,
    locale: sqlDatasetLocale,
    studiesDetailsModal,
    setStudiesDetailsModal,
  } = useSQLDatasetColumns();
  const [selectedTableItems, setSelectedTableItems] = useState<Record<string, any>>({});

  useEffect(() => {
    if (analysisPackageById.data) {
      setSelectedTableItems(analysisPackageById.data?.objects!);

      form.setFieldsValue({
        version: analysisPackageById.data.version,
        description: analysisPackageById.data.description,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [analysisPackageById.data]);

  const initValues =
    isView && data
      ? {
          version: data.version,
          description: data.description,
        }
      : {
          version: '',
          description: '',
        };

  const tableDataModels =
    ((isView && data ? analysisPackageById.data?.objects.data_model : dataModelAnalysisObjects.data?.items) as
      | ILibraryModel[]) || [];
  const tableDataCdr =
    ((isView && data
      ? analysisPackageById.data?.objects.cdr_report
      : cdrAnalysisObjects.data?.items) as ILibraryCDR[]) || [];
  const tableDataSQLDatasets =
    ((isView && data ? analysisPackageById.data?.objects.sql_lab : sqlDatasetsObjects.data?.items) as any[]) || [];

  const isSaveDisabled = Object.values(selectedTableItems).every((v) => v.length === 0);

  const errorMessage = [saveAnalysisPackageQuery, analysisPackageById]
    .map(
      (result) =>
        result.isError && result.error && ('data' in result.error ? result.error.data.userMsg : result.error?.message),
    )
    .filter(Boolean)
    .join(';');

  const onSubmit = async (values: IAnalysisPackageSaveFieldFormValues) => {
    try {
      await saveAnalysisPackage({
        ...values,
        objects: selectedTableItems,
      }).unwrap();
      notification.success({
        message: t('saveForm.successMessageCreate', { name: values.version }),
      });
      onClose();
    } catch (e) {
      console.error('Error while saving analysis package', e);
      notification.error({
        message: t('saveForm.errorMessageCreate', { name: values.version }),
      });
    }
  };

  const tabs: TabProps<IBaseColumnProps>[] = [
    {
      kind: ELibraryEntityKind.Model,
      label: t('Data Models'),
      columns: modelColumns as TableColumnsType<IBaseColumnProps>,
      locale: modelLocale,
      tableDataFetching: dataModelAnalysisObjects.isFetching,
      tableData: getLibraryImportModelFromLibrarySource(tableDataModels, ELibrarySourceType.Library),
    },
    {
      kind: ELibraryEntityKind.CDR,
      label: t('CDR'),
      columns: cdrColumns as TableColumnsType<IBaseColumnProps>,
      locale: cdrLocale,
      tableDataFetching: cdrAnalysisObjects.isFetching,
      tableData: getLibraryImportCDRFromLibrarySource(tableDataCdr, ELibrarySourceType.Library),
    },
    {
      kind: ELibraryEntityKind.SQL_Lab,
      label: t('SQL Datasets'),
      columns: sqlDatasetsColumns as TableColumnsType<IBaseColumnProps>,
      locale: sqlDatasetLocale,
      tableDataFetching: sqlDatasetsObjects.isFetching,
      tableData: tableDataSQLDatasets,
    },
  ];

  return (
    <FormLayout
      form={form}
      onCancel={onClose}
      initialValues={initValues}
      onSubmit={onSubmit}
      cancelText={isView ? t('close') : ''}
      okText={t('save')}
      disabled={isView}
      submitIsDisabled={isSaveDisabled || isView}
      hideOkBtn={isView}
    >
      <FormItem name="version" label={t('saveForm.version')} rules={[{ required: true }]}>
        <Input />
      </FormItem>
      <FormItem name="description" label={t('saveForm.description')} rules={[{ required: true }]}>
        <Input />
      </FormItem>
      <FormItem wrapperCol={{ span: 24 }}>
        <Tabs
          defaultActiveKey={ELibraryEntityKind.Model}
          type="card"
          size="small"
          items={tabs.map((tab) => ({
            key: tab.kind,
            label: tab.label,
            children: (
              <ObjectTable
                data={data}
                kind={tab.kind}
                columns={tab.columns}
                locale={tab.locale}
                tableDataFetching={tab.tableDataFetching || analysisPackageById.isFetching}
                tableData={tab.tableData || []}
                selectedTableItems={selectedTableItems}
                setSelectedTableItems={setSelectedTableItems}
                hideCheckboxes={isView}
              />
            ),
          }))}
        />
      </FormItem>
      {studiesDetailsModal && (
        <InformationModal
          message={renderInfoModalContent(studiesDetailsModal)}
          onClose={() => setStudiesDetailsModal(null)}
          width="500px"
          autoHeightMin={50}
        />
      )}
      {errorMessage && <Typography.Text type="danger">{errorMessage}</Typography.Text>}
    </FormLayout>
  );
};

export const AnalysisPackageModalsSaveAnalysisPackage = ({
  open,
  data,
  onClose,
}: AnalysisPackageModalsSaveAnalysisPackageProps) => {
  const { t } = useTranslation(['analysisPackage']);
  const isView = !!(data as IAnalysisPackage)?.id;

  return (
    <DraggableModal
      width="50%"
      open={open}
      onCancel={onClose}
      title={isView ? t('saveForm.titleView') : t('saveForm.title')}
      footer={null}
      destroyOnClose
    >
      {open && <AnalysisPackageModalsSaveAnalysisPackageContent data={data} onClose={onClose} t={t} isView={isView} />}
    </DraggableModal>
  );
};
export interface AnalysisPackageModalsSaveAnalysisPackageProps {
  open: boolean;
  data: Partial<Pick<IAnalysisPackage, 'id' | 'version' | 'description'>>;
  onClose: () => void;
}

interface AnalysisPackageModalsSaveAnalysisPackageContentProps
  extends Pick<AnalysisPackageModalsSaveAnalysisPackageProps, 'data' | 'onClose'> {
  t: TFunction;
  isView: boolean;
}

interface IAnalysisPackageSaveFieldFormValues {
  id?: number;
  version: string;
  description: string;
  objects: IAnalysisPackage['objects'];
}

interface TabProps<T> {
  kind: ELibraryEntityKind;
  label: string;
  columns: TableColumnsType<T>;
  locale: Record<string, any>;
  tableDataFetching: boolean;
  tableData: T[];
}
